import React, { useEffect, useRef, useState } from "react";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import axios from 'axios';
import { LoadingIndicator } from "@optum-osgp-temp/osgp-ui-component-lib";
import './pdfViewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const backendApi = process.env.REACT_APP_BACKEND_URL;

export default function PdfViewer({ selectedItem, onReceiveResponse }) {
const inputRef = useRef(null);
const canvasContainerRef = useRef(null);
const [pdfDocument, setPdfDocument] = useState(null);
// const [canvasData, setCanvasData] = useState([]);
const [isLoading,setIsLoading] = useState(false);

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    setIsLoading(true);
    onReceiveResponse(null);
    const reader = new FileReader(); 
      reader.onloadend = async () => {
        const arrayBuffer = reader.result;
        const typedArray = new Uint8Array(arrayBuffer);
        const loadingTask = pdfjsLib.getDocument(typedArray);
        const pdf = await loadingTask.promise; 
        // let content = new TextDecoder().decode(typedArray);
        const formdata = new FormData();
        formdata.append('file',file);
        try{
          const response = await axios.post(backendApi+'api/test/analyze-custom-doc-model-data', formdata ,{
            headers : {
              'Content-Type' : 'application/octet-stream',
            },
          });
          const data = response.data;
          const jsonData = JSON.parse(data.jsonOutput);
          console.log('Response from API', jsonData);
          onReceiveResponse(jsonData);
        }
        catch(error){
          console.error('Error sending data ',error);
        }
        setPdfDocument(pdf);
        setIsLoading(false);
      };
      reader.readAsArrayBuffer(file);
    }
}

const drawCanvasOnPage = async (page) => {
  const canvas = document.createElement("canvas");
  const canvasContainer = canvasContainerRef.current;
  canvasContainer.appendChild(canvas);
  const ctx = canvas.getContext("2d");
  const viewport = page.getViewport({ scale: 1 });
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: ctx,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
}

const loadPages = async () => {
  if (!pdfDocument) return;
  const numPages = pdfDocument.numPages;
    for (let i = 1; i <= numPages; i++) {
      const page = await pdfDocument.getPage(i);
      await drawCanvasOnPage(page);
    }
}

useEffect(() => {
  if (pdfDocument) {
    loadPages();  
  }
}, [pdfDocument])

// useEffect(() => {
//   if (selectedItem && selectedItem.value) {
//     let boundPoly = selectedItem.Cordinates[0].boundingPolygon;
//     let pageNum = selectedItem.Cordinates[0].pageNumber;
//     const newRectangle={
//       pageNumber:pageNum,
//       x : boundPoly[0].xCoordinate,
//       y : boundPoly[0].yCoordinate,
//       width : (boundPoly[1].xCoordinate - boundPoly[0].xCoordinate)/8.5,
//       height : (boundPoly[2].yCoordinate - boundPoly[1].yCoordinate)/11,
//       };
//       const canvasDataEntry = canvasData[pageNum-1];
//       console.log(canvasDataEntry);
//       if(canvasDataEntry){
//         const {ctx,canvasWidth,canvasHeight} = canvasDataEntry;
//         const canvasX = newRectangle.x * canvasWidth;
//         const canvasY = newRectangle.y * canvasHeight;
//         const canvasRectWidth = newRectangle.width * canvasWidth;
//         const canvasRectHeight = newRectangle.height * canvasWidth;
//         ctx.strokeStyle = "#1B9AFF";
//         ctx.lineWidth = 2;
//         ctx.strokeRect(canvasX, canvasY, canvasRectWidth, canvasRectHeight);
//       }
//   }
//   }, [selectedItem,canvasData]);

  return (
        <>
          <input aria-label="input file" type="file" accept=".pdf" ref={inputRef} onChange={handleFileChange} className="inputFile uitkBtnBoldGhost"/>
          {isLoading ? (<LoadingIndicator size={"m"} loading={true} className="loader"/>):(<div ref={canvasContainerRef} data-testid='canvas-container' className="canvas-container"></div>)}
        </>
  );
}