import React,{useState} from 'react';
import PdfViewer from '../components/pdfViewer';
import '../App.css';
import DefaultExample from '../components/Table';

function Home(){
  const [responseData, setResponseData] = useState(null);   
  const handleResponseData = (data) => {  
    setResponseData(data);  
  };
    return(
        <div>
        <div className='page'>
            <div className='pdf'>
              <PdfViewer onReceiveResponse={handleResponseData} />
            </div>
            <div className='model'>
                <DefaultExample  data={responseData}/>
            </div>
        </div>
        </div>
    );
}
export default Home