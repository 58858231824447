import React from 'react';  
import { DataGrid,OSGPSelectWithTag } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';  
import { renderWeights, handleStatusChange, newRows, handleConfidenceInputChange, renderCheckbox, handleCheckboxChange, fetchModelsFromApi,handleSelectedModels,handleSubmitButton,fetchCertaintyFromApi} from '../Utility/reUsable';  
import { Button, TextInput, Label, Icon, Tooltip, TabbedPanel,Notification } from '@optum-osgp-temp/osgp-ui-component-lib';  
import { useState, useEffect } from 'react';  
import './Table.css';

const DefaultExample = ({ data }) => {  
  const [confidenceInput, setConfidenceInput] = useState(0.85);  
  const [gridRows, setGridRows] = useState([]); 
  const [integrityModels,setIntegrityModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]); 
  const [currentPanel, setCurrentPanel] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const dismissInfo = () => setInfoOpen(false);
  const [threshold,setThreshold] = useState(0);  
  
  const handleToggle = () => {  
    setToggle(!toggle);  
  }; 
  
  useEffect(async () => {  
    setGridRows(newRows(data)); 
    fetchModelsFromApi(setIntegrityModels);
    let value = await fetchCertaintyFromApi();
    setThreshold(value);
  }, [data]);  
  
  const columns = [  
    { field: 'keyContent', headerName: 'Form Fields', flex:2 },  
    { field: 'weights', headerName: 'Weights', flex:1, renderCell: (params) => renderWeights(params, handleStatusChangeWrap) },  
    { field: 'checkbox', headerName: 'Integrity Check?', flex:1, renderCell: (params) => renderCheckbox(params, handleCheckboxChange,gridRows,setGridRows) },  
  ]; 
  
  const panels = [
    {
      header: "Weight",
      content: 
      <>
      <div className ='datagrid'>
          <DataGrid
            key={gridRows.length}
            columns={columns}
            rows={gridRows}
            rowHeight={30}
            editMode="row"
            autoPageSize
            disableToolbarFilter
            pageSizeOptions={[60]}
            disableRowSelectionOnClick />
        </div></> ,
    },
    {
      header: "Integrity Model",
      content:<>
      <div>  
        <h4>Do you want to perform the integrity checks on the form ? </h4>
          <label className="switch">  
            <input type="checkbox" checked={toggle} onChange={handleToggle} />  
            <span className="slider"></span>  
          </label>  
      </div>  
      {toggle && (
      <>
        <h4>Select the integrity checks to be performed</h4>
        <OSGPSelectWithTag
            isMultiSelect
            options={integrityModels}
            selectedValues={(items) => handleSelectedModels(items, setSelectedModels)} /></>) }
      </>
    },
  ];

  const handleStatusChangeWrap = (params, event) => {  
    handleStatusChange(gridRows, params, event, setGridRows);  
  }   
  
  function handleConfidenceInputChangeWrap(event) {  
    handleConfidenceInputChange(event, setConfidenceInput,setInfoOpen,threshold);  
  }   

  return (  
    <div>  
      <div className="container">  
        <Label style={{ marginTop: '5px', marginLeft: '8px' }}>Threshold Certainty<Tooltip content="Certainty score for every field below which human review is mandatory" placement='bottom' maxWidth='350px'>  
          <Icon iconName="InfoFilled" />  
        </Tooltip> : </Label>  
        <TextInput className="input" style={{ width: '100px', marginLeft: '5px' }} type={'number'} min='0' max='1' step={0.01} value={confidenceInput} onChange={handleConfidenceInputChangeWrap}></TextInput>  
      </div>  
      <Notification
        onDismiss={dismissInfo}
        buttonChildren={"close"}
        variant={"info"}
        id={"notification-controlled-info"}
        show={infoOpen}
        className="mv-m"
        style={{maxWidth:"50vw",cursor:"pointer",marginTop:"1%"}}>The user has set a minimum threshold value of {threshold}. Please ensure your input is above this minimum value.</Notification>
      <div style={{ marginLeft: '650px', marginTop: '10px', marginBottom: '2px' }}>  
        <Button variant="ghost" data-testid='SubmitBtn' onPress={() => handleSubmitButton(currentPanel,gridRows,selectedModels,confidenceInput,toggle)}>Submit</Button>  
      </div>  
      <TabbedPanel panels={panels} titlesAs="h2" onHeaderPress={setCurrentPanel} selectedPanel={currentPanel} />       
    </div>  
  );  
}  
export default DefaultExample