import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';

const backendApi = process.env.REACT_APP_BACKEND_URL;

const UseStyles = makeStyles((theme) => ({
    select: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
    },
  }));

export function getVal(name){
    let x;
    if(name==='High'){
      x = 300;
     }
     if(name==='Medium'){
       x=200;
     }
     if(name==='Low'){
       x=100;
     }
     if(name==='N/A'){
       x=0;
     }
     return(x);
  }

  export function handleStatusChange(gridRows,params, e,setGridRows){
    const newRows = gridRows.map(row =>
        row.id === params.id ? { ...row, weights: e.target.value } : row
       );
    const status = e.target.value;
    let deno=0;
    let numo=0
    for(let i=0;i<gridRows.length;i++){
      if(i===(params.id-1)){
        numo+=gridRows[i].confidenceValue*getVal(status);
        deno+=getVal(status);
      }
      else{
        numo+=gridRows[i].confidenceValue*getVal(gridRows[i].weights);
        deno+=getVal(gridRows[i].weights)
      }
     };
     setGridRows(newRows);
  }

  export const renderWeights = (params,handleStatusChangeWrap) => {
    const classes = UseStyles(); 
    return(
      <div className="single-select-cell">
          <Select
            value={params.value}
            onChange={(e) => handleStatusChangeWrap(params, e)}
            variant="outlined"
            className={classes.select}>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="N/A">N/A</MenuItem>
          </Select>
        </div>
        );
  }

  export function handleReturn(gridRows,confidenceInput){
    const keyColumn = gridRows.map((row) => row.keyContent);
    const weightColumn = gridRows.map((row) => row.weights);
    const weightDict = {};
    keyColumn.forEach((key,index) => {
      weightDict[key] = weightColumn[index];
    });
    axios.post(backendApi+'api/blobs/updateFormData', weightDict,{ params: {  
      thresholdCertainty: confidenceInput }  })
    .then((response) => {
      // console.log(response.status);
      if(response.status == '200' || response.status == '202'){
        alert('Successful Upload!!!')
      }
    })
    .catch((error) => {
      console.error(error); 
    });
  }

  export function handleIntegrityChecks(selectedModels,gridRows,toggle){
    const keyColumn = gridRows.map((row) => row.keyContent);
    const checkBoxColumn = gridRows.map((row) => row.checkboxes);
    const fieldIntegrity = {};
    keyColumn.forEach((key,index) => {
      if (checkBoxColumn[index]) { // Add to fieldIntegrity only if the checkbox value is true  
        fieldIntegrity[key] = checkBoxColumn[index];  
      }
    });
    const requestData = {  
      selectedModels: selectedModels,  
      fieldIntegrity: fieldIntegrity,
      performIntegrity: toggle
    }; 
    axios.post(backendApi+'api/admin/update-integrityChecksFields', requestData)
    .then((response) => {
      if(response.status == '200' || response.status == '202'){
        alert('Successfully Uploaded Integrity Checks And Mandatory Fields!!!')
      }
    })
    .catch((error) => {
      console.error(error); 
    });
  }

  export function newRows(data){
    if(data){
      const dataMap = data.map((pair, index) => ({  
        id: index + 1,    
        keyContent: pair.key || '',    
        weights: 'Medium',
        checkboxes: false
        }));
      return dataMap;
    }
    else{
      return [];
    }
  }

  export const fetchCertaintyFromApi = async () => {
    try {
      const response = await axios.get(backendApi + 'api/blobs/certainty');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export function handleConfidenceInputChange(event,setConfidenceInput,setInfoOpen,threshold){
    const inputValue = parseFloat(event.target.value);
    if(inputValue < threshold && inputValue > 0){
        setInfoOpen(true);
        setConfidenceInput(threshold);
    } 
    else{
      setConfidenceInput(inputValue);
    }
  } 

  export function renderCheckbox(params, handleCheckboxChange,gridRows,setGridRows) {  
    return (  
      <input type="checkbox" checked={params.row.selected} onChange={(event) => handleCheckboxChange(params, event, gridRows, setGridRows)} className='Checkboxcomponent__Label-sc-yui814-1 nUmJY uitk-checkbox-label' /> 
      // <Checkbox checked={params.row.selected} onChange={(event) => handleCheckboxChange(params,event)}></Checkbox> 
    );  
  }  
  
  export function handleCheckboxChange(params, event, gridRows, setGridRows) {  
    const updatedRows = gridRows.map(row =>  
      row.id === params.row.id ? { ...row, checkboxes: event.target.checked } : row  
    );  
    setGridRows(updatedRows);  
  } 

  export const fetchModelsFromApi = async (setIntegrityModels) => {    
    try {    
      const response = await axios.get(backendApi+'api/admin/integrity-checks');    
      const integrityString = response.data;   
      if (integrityString.length === 0) {  
        setIntegrityModels([]);  
      } else{
        const integrityArray = integrityString.split(',').map((item) => item.replace('[', '').replace(']', ''));   
        const updatedOptions = integrityArray.map((item, index) => ({    
          label: item.trim(),    
          value: item.trim().toLowerCase().replace(/\s/g, ""),    
          id: index + 1,    
        }));    
      
        setIntegrityModels(updatedOptions);
      }     
    } catch (error) {    
      console.error('Error fetching data:', error);    
      throw error; // Propagate the error to the caller    
    }    
  }  
  
  
  export const handleSelectedModels = (items,setSelectedModels) => {  
    const selectedValues = items.map((item) => item.label);  
    setSelectedModels(selectedValues);  
  };

  export function handleSubmitButton(currentPanel, gridRows, selectedModels, confidenceInput, toggle) {  
    switch (currentPanel) {  
      case 0:  
        handleReturn(gridRows, confidenceInput);  
        break;  
      case 1:  
        handleIntegrityChecks(selectedModels, gridRows, toggle);  
        break;  
      default:  
        // Handle other cases or throw an error  
        break;  
    }  
  }  