import React from "react";
import { Header } from "@optum-osgp-temp/osgp-ui-component-lib";

const defaultLogo = (
  <>
      <div style={{ display: 'flex', flexDirection:'row',position:'relative',height:'25px' }}>
        <div className="Logo_container" style={{ display: 'flex', alignItems: 'center' , }}>
          <img
            src={require('../assets/optum.png')}
            alt="logo"
          />
        </div>
          <div className="productName_container">
            <h3 style={{ margin: '1rem 1rem 0 1rem', color: 'grey' }}>Document Intelligence Platform</h3>
          </div>
      </div>
    </>
);

export const HeaderUserProfileExample: FC<{ logoContent?: any}> = ({
  logoContent = defaultLogo,
}) => <Header  logoContent={logoContent}
     productName ="" productLink ="Admin Portal"
 />;

 export default HeaderUserProfileExample