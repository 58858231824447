// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {  
  position: relative;  
  display: inline-block;  
  width: 60px;  
  height: 24px;  
}  

.colorConfidence{
  color:blue;
}

.input{
  width: 150px;
  padding: 20px;
  height: 30px;
  margin-right: 110px;
}

.body{
  overflow: hidden;
}

.buMAvo{
  border:none !important;
}

.datagrid{
  height: 430px;
  overflow: auto; 
  margin: 1px; 
}
.switch input {  
  opacity: 0;  
  width: 0;  
  height: 0;  
}  
  
.slider {  
  position: absolute;  
  cursor: pointer;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  background-color: #ccc;  
  transition: .4s;  
  border-radius: 34px;  
}  
  
.slider:before {  
  position: absolute;  
  content: "";  
  height: 16px;  
  width: 26px;  
  left: 4px;  
  bottom: 4px;  
  background-color: white;  
  transition: .4s;  
  border-radius: 50%;  
}  
  
input:checked + .slider {  
  background-color: #2196F3;  
}  
  
input:focus + .slider {  
  box-shadow: 0 0 1px #2196F3;  
}  
  
input:checked + .slider:before {  
  transform: translateX(26px);  
}  
  
.slider.round {  
  border-radius: 24px;  
}  
  
.slider.round:before {  
  border-radius: 20%;  
}  `, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,WAAW;AACb;AACA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".switch {  \n  position: relative;  \n  display: inline-block;  \n  width: 60px;  \n  height: 24px;  \n}  \n\n.colorConfidence{\n  color:blue;\n}\n\n.input{\n  width: 150px;\n  padding: 20px;\n  height: 30px;\n  margin-right: 110px;\n}\n\n.body{\n  overflow: hidden;\n}\n\n.buMAvo{\n  border:none !important;\n}\n\n.datagrid{\n  height: 430px;\n  overflow: auto; \n  margin: 1px; \n}\n.switch input {  \n  opacity: 0;  \n  width: 0;  \n  height: 0;  \n}  \n  \n.slider {  \n  position: absolute;  \n  cursor: pointer;  \n  top: 0;  \n  left: 0;  \n  right: 0;  \n  bottom: 0;  \n  background-color: #ccc;  \n  transition: .4s;  \n  border-radius: 34px;  \n}  \n  \n.slider:before {  \n  position: absolute;  \n  content: \"\";  \n  height: 16px;  \n  width: 26px;  \n  left: 4px;  \n  bottom: 4px;  \n  background-color: white;  \n  transition: .4s;  \n  border-radius: 50%;  \n}  \n  \ninput:checked + .slider {  \n  background-color: #2196F3;  \n}  \n  \ninput:focus + .slider {  \n  box-shadow: 0 0 1px #2196F3;  \n}  \n  \ninput:checked + .slider:before {  \n  transform: translateX(26px);  \n}  \n  \n.slider.round {  \n  border-radius: 24px;  \n}  \n  \n.slider.round:before {  \n  border-radius: 20%;  \n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
