// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width:962px) {
    .canvas-container{
      position:relative;
      height: 100vh;
      width: 98%;
      overflow: auto;
      flex-basis: 50%;
    }
    .canvas{
      height: 480px;
      width: 480px;
      min-width: 40%;
      overflow: auto;
      flex-basis: 50%;
    }
    
    
    .button{
      position: absolute;
      top: 65px;
      right: 60%;
    }
    
    .loader{
      margin: 160px;
    }
    
    .inputFile{
      margin-bottom: 25px;
      margin-top: 5px;
      margin-right: 90px;
    }
}
    
@media only screen and (max-width: 768px) { 
    .canvas-container{
    position:relative;
    height: 680px;
    width: 38%;
    overflow: auto;
    flex-basis: 40%;
    }
    .canvas{
    position:absolute;
    height: 680px;
    width: 38%;
    overflow: auto;
    flex-basis: 50%;
    }
    
    .loader{
    margin: 160px;
    }
    
    .inputFile{
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pdfViewer.css"],"names":[],"mappings":"AAAA;IACI;MACE,iBAAiB;MACjB,aAAa;MACb,UAAU;MACV,cAAc;MACd,eAAe;IACjB;IACA;MACE,aAAa;MACb,YAAY;MACZ,cAAc;MACd,cAAc;MACd,eAAe;IACjB;;;IAGA;MACE,kBAAkB;MAClB,SAAS;MACT,UAAU;IACZ;;IAEA;MACE,aAAa;IACf;;IAEA;MACE,mBAAmB;MACnB,eAAe;MACf,kBAAkB;IACpB;AACJ;;AAEA;IACI;IACA,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,cAAc;IACd,eAAe;IACf;IACA;IACA,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,cAAc;IACd,eAAe;IACf;;IAEA;IACA,aAAa;IACb;;IAEA;IACA,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB;AACJ","sourcesContent":["@media only screen and (min-width:962px) {\n    .canvas-container{\n      position:relative;\n      height: 100vh;\n      width: 98%;\n      overflow: auto;\n      flex-basis: 50%;\n    }\n    .canvas{\n      height: 480px;\n      width: 480px;\n      min-width: 40%;\n      overflow: auto;\n      flex-basis: 50%;\n    }\n    \n    \n    .button{\n      position: absolute;\n      top: 65px;\n      right: 60%;\n    }\n    \n    .loader{\n      margin: 160px;\n    }\n    \n    .inputFile{\n      margin-bottom: 25px;\n      margin-top: 5px;\n      margin-right: 90px;\n    }\n}\n    \n@media only screen and (max-width: 768px) { \n    .canvas-container{\n    position:relative;\n    height: 680px;\n    width: 38%;\n    overflow: auto;\n    flex-basis: 40%;\n    }\n    .canvas{\n    position:absolute;\n    height: 680px;\n    width: 38%;\n    overflow: auto;\n    flex-basis: 50%;\n    }\n    \n    .loader{\n    margin: 160px;\n    }\n    \n    .inputFile{\n    margin-bottom: 5px;\n    margin-top: 5px;\n    margin-right: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
