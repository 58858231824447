import React from "react";
import {Outlet} from "react-router-dom";
import { HeaderUserProfileExample } from "../components/NavigationBar";

const Layout = () => {
  return (
    <div style={{marginBottom:'5px'}}>
        <HeaderUserProfileExample/>
        <Outlet />
    </div>
  );
}
export default Layout