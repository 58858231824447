import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import {
  optum, OSGPToolkit
} from "@optum-osgp-temp/osgp-ui-component-lib";
import "@optum-osgp-temp/osgp-ui-component-lib/themes/optum.css";

export default function App() {
  return (
    <OSGPToolkit theme={optum} grid spacing>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </OSGPToolkit> 
  );
}
ReactDOM.render(<App />, document.getElementById("root"));